import { ErrorMessage } from '@hookform/error-message';
import { Button, Checkbox, Input } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/package.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { getValueForInputNumber } from '@/helpers/functions/character.helper';
import {
  validateIntegerNumber,
  validateNonNegativeNumber,
} from '@/helpers/functions/form-validation.helper';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const FunctionsManagement = () => {
  useDocumentTitle(translate(locale_keys.menu.function));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const defaultValues = {
    addVideoWhenCreatePollAnonymous: false,
    addVideoWhenCreatePollFree: false,
    addVideoWhenCreatePollBasic: false,
    addVideoWhenCreatePollPro: false,
    addVideoWhenCreatePollAdvanced: false,

    addOptionAnonymous: false,
    addOptionFree: false,
    addOptionBasic: false,
    addOptionPro: false,
    addOptionAdvanced: false,

    addAttachmentInCommentAnonymous: false,
    addAttachmentInCommentFree: false,
    addAttachmentInCommentBasic: false,
    addAttachmentInCommentPro: false,
    addAttachmentInCommentAdvanced: false,

    addAttachmentInOptionAnonymous: false,
    addAttachmentInOptionFree: false,
    addAttachmentInOptionBasic: false,
    addAttachmentInOptionPro: false,
    addAttachmentInOptionAdvanced: false,

    addCommentAnonymous: false,
    addCommentFree: false,
    addCommentBasic: false,
    addCommentPro: false,
    addCommentAdvanced: false,

    uploadFileCsvAnonymous: false,
    uploadFileCsvFree: false,
    uploadFileCsvBasic: false,
    uploadFileCsvPro: false,
    uploadFileCsvAdvanced: false,

    maxEmailsAnonymous: 0,
    maxEmailsFree: 0,
    maxEmailsBasic: 0,
    maxEmailsPro: 0,
    maxEmailsAdvanced: 0,

    maxPollsInDayAnonymous: 0,
    maxPollsInDayFree: 0,
    maxPollsInDayBasic: 0,
    maxPollsInDayPro: 0,
    maxPollsInDayAdvanced: 0,
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const getConfigsFunction = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.settings);
      const whiteListFunc = data?.data?.whiteListFunction;
      reset({
        addVideoWhenCreatePollAnonymous:
          whiteListFunc?.addVideoWhenCreatePoll?.anonymous,
        addVideoWhenCreatePollFree: whiteListFunc?.addVideoWhenCreatePoll?.free,
        addVideoWhenCreatePollBasic:
          whiteListFunc?.addVideoWhenCreatePoll?.basic,
        addVideoWhenCreatePollPro: whiteListFunc?.addVideoWhenCreatePoll?.pro,
        addVideoWhenCreatePollAdvanced:
          whiteListFunc?.addVideoWhenCreatePoll?.advanced,

        addOptionAnonymous: whiteListFunc?.addOption?.anonymous,
        addOptionFree: whiteListFunc?.addOption?.free,
        addOptionBasic: whiteListFunc?.addOption?.basic,
        addOptionPro: whiteListFunc?.addOption?.pro,
        addOptionAdvanced: whiteListFunc?.addOption?.advanced,

        addAttachmentInCommentAnonymous:
          whiteListFunc?.addAttachmentInComment?.anonymous,
        addAttachmentInCommentFree: whiteListFunc?.addAttachmentInComment?.free,
        addAttachmentInCommentBasic:
          whiteListFunc?.addAttachmentInComment?.basic,
        addAttachmentInCommentPro: whiteListFunc?.addAttachmentInComment?.pro,
        addAttachmentInCommentAdvanced:
          whiteListFunc?.addAttachmentInComment?.advanced,

        addAttachmentInOptionAnonymous:
          whiteListFunc?.addAttachmentInOption?.anonymous,
        addAttachmentInOptionFree: whiteListFunc?.addAttachmentInOption?.free,
        addAttachmentInOptionBasic: whiteListFunc?.addAttachmentInOption?.basic,
        addAttachmentInOptionPro: whiteListFunc?.addAttachmentInOption?.pro,
        addAttachmentInOptionAdvanced:
          whiteListFunc?.addAttachmentInOption?.advanced,

        addCommentAnonymous: whiteListFunc?.addComment?.anonymous,
        addCommentFree: whiteListFunc?.addComment?.free,
        addCommentBasic: whiteListFunc?.addComment?.basic,
        addCommentPro: whiteListFunc?.addComment?.pro,
        addCommentAdvanced: whiteListFunc?.addComment?.advanced,

        uploadFileCsvAnonymous: whiteListFunc?.uploadFileCsv?.anonymous,
        uploadFileCsvFree: whiteListFunc?.uploadFileCsv?.free,
        uploadFileCsvBasic: whiteListFunc?.uploadFileCsv?.basic,
        uploadFileCsvPro: whiteListFunc?.uploadFileCsv?.pro,
        uploadFileCsvAdvanced: whiteListFunc?.uploadFileCsv?.advanced,

        maxEmailsAnonymous: whiteListFunc?.maxEmails?.anonymous,
        maxEmailsFree: whiteListFunc?.maxEmails?.free,
        maxEmailsBasic: whiteListFunc?.maxEmails?.basic,
        maxEmailsPro: whiteListFunc?.maxEmails?.pro,
        maxEmailsAdvanced: whiteListFunc?.maxEmails?.advanced,

        maxPollsInDayAnonymous: whiteListFunc?.maxPollsInDay?.anonymous,
        maxPollsInDayFree: whiteListFunc?.maxPollsInDay?.free,
        maxPollsInDayBasic: whiteListFunc?.maxPollsInDay?.basic,
        maxPollsInDayPro: whiteListFunc?.maxPollsInDay?.pro,
        maxPollsInDayAdvanced: whiteListFunc?.maxPollsInDay?.advanced,
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRefresh = () => {
    getConfigsFunction();
  };

  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const reqData = {
        whiteListFunction: {
          addVideoWhenCreatePoll: {
            anonymous: dataForm?.addVideoWhenCreatePollAnonymous,
            free: dataForm?.addVideoWhenCreatePollFree,
            basic: dataForm?.addVideoWhenCreatePollBasic,
            pro: dataForm?.addVideoWhenCreatePollPro,
            advanced: dataForm?.addVideoWhenCreatePollAdvanced,
          },
          addOption: {
            anonymous: dataForm?.addOptionAnonymous,
            free: dataForm?.addOptionFree,
            basic: dataForm?.addOptionBasic,
            pro: dataForm?.addOptionPro,
            advanced: dataForm?.addOptionAdvanced,
          },
          addAttachmentInComment: {
            anonymous: dataForm?.addAttachmentInCommentAnonymous,
            free: dataForm?.addAttachmentInCommentFree,
            basic: dataForm?.addAttachmentInCommentBasic,
            pro: dataForm?.addAttachmentInCommentPro,
            advanced: dataForm?.addAttachmentInCommentAdvanced,
          },
          addAttachmentInOption: {
            anonymous: dataForm?.addAttachmentInOptionAnonymous,
            free: dataForm?.addAttachmentInOptionFree,
            basic: dataForm?.addAttachmentInOptionBasic,
            pro: dataForm?.addAttachmentInOptionPro,
            advanced: dataForm?.addAttachmentInOptionAdvanced,
          },
          addComment: {
            anonymous: dataForm?.addCommentAnonymous,
            free: dataForm?.addCommentFree,
            basic: dataForm?.addCommentBasic,
            pro: dataForm?.addCommentPro,
            advanced: dataForm?.addCommentAdvanced,
          },
          uploadFileCsv: {
            anonymous: defaultValues.uploadFileCsvAnonymous,
            free: dataForm?.uploadFileCsvFree,
            basic: dataForm?.uploadFileCsvBasic,
            pro: dataForm?.uploadFileCsvPro,
            advanced: dataForm?.uploadFileCsvAdvanced,
          },
          maxEmails: {
            anonymous: getValueForInputNumber(dataForm?.maxEmailsAnonymous),
            free: getValueForInputNumber(dataForm?.maxEmailsFree),
            basic: getValueForInputNumber(dataForm?.maxEmailsBasic),
            pro: getValueForInputNumber(dataForm?.maxEmailsPro),
            advanced: getValueForInputNumber(dataForm?.maxEmailsAdvanced),
          },
          maxPollsInDay: {
            anonymous: getValueForInputNumber(dataForm?.maxPollsInDayAnonymous),
            free: getValueForInputNumber(dataForm?.maxPollsInDayFree),
            basic: getValueForInputNumber(dataForm?.maxPollsInDayBasic),
            pro: getValueForInputNumber(dataForm?.maxPollsInDayPro),
            advanced: getValueForInputNumber(dataForm?.maxPollsInDayAdvanced),
          },
        },
      };

      const { data } = await apiService.patch(endpointConfig.settings, reqData);

      if (data?.success) {
        toast.success(
          translate(
            locale_keys.paymentManagement.functionConfig.toastr.updateSuccessful
          )
        );
        getConfigsFunction();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getConfigsFunction();
  }, [location]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='function'>
        <div className='table__heading d-flex justify-content-between align-items-center'>
          <div className='table__heading-title'>
            {translate(
              locale_keys.paymentManagement.functionConfig.listFunction
            )}
          </div>
          <div className='d-flex mb-20'>
            <Button className='form-btn' onClick={onRefresh}>
              {translate(locale_keys.filter.refresh)}
            </Button>
            <Button
              className='form-btn form-btn--black ml-20'
              htmlType='submit'
            >
              {translate(locale_keys.filter.save)}
            </Button>
          </div>
        </div>
        <table className='table table-bordered table-responsive'>
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: '40%' }}>
                {translate(locale_keys.paymentManagement.functionConfig.action)}
              </th>
              <th rowSpan={2} style={{ width: '12%' }}>
                {translate(
                  locale_keys.paymentManagement.functionConfig.anonymous
                )}
              </th>
              <th rowSpan={2} style={{ width: '12%' }}>
                {translate(
                  locale_keys.paymentManagement.functionConfig.userFree
                )}
              </th>
              <th colSpan={3}>
                {translate(
                  locale_keys.paymentManagement.functionConfig.paidUser
                )}
              </th>
            </tr>
            <tr>
              <th style={{ width: '12%' }}>
                {translate(
                  locale_keys.paymentManagement.packageConfig.basicTitle
                )}
              </th>
              <th style={{ width: '12%' }}>
                {translate(
                  locale_keys.paymentManagement.packageConfig.proTitle
                )}
              </th>
              <th style={{ width: '12%' }}>
                {translate(
                  locale_keys.paymentManagement.packageConfig.advancedTitle
                )}
              </th>
            </tr>
          </thead>
          <tbody id='rows'>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.limitPost
                )}
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxPollsInDayAnonymous'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxPollsInDayAnonymous'],
                        })}
                        {...register('maxPollsInDayAnonymous', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxPollsInDayAnonymous'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxPollsInDayFree'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxPollsInDayFree'],
                        })}
                        {...register('maxPollsInDayFree', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxPollsInDayFree'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxPollsInDayBasic'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxPollsInDayBasic'],
                        })}
                        {...register('maxPollsInDayBasic', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxPollsInDayBasic'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxPollsInDayPro'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxPollsInDayPro'],
                        })}
                        {...register('maxPollsInDayPro', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxPollsInDayPro'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxPollsInDayAdvanced'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxPollsInDayAdvanced'],
                        })}
                        {...register('maxPollsInDayAdvanced', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxPollsInDayAdvanced'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.uploadVideo
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addVideoWhenCreatePollAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addVideoWhenCreatePollFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addVideoWhenCreatePollBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addVideoWhenCreatePollPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addVideoWhenCreatePollAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.addOption
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addOptionAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addOptionFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addOptionBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addOptionPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addOptionAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.uploadMediaOption
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInOptionAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInOptionFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInOptionBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInOptionPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInOptionAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.comment
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addCommentAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addCommentFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addCommentBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addCommentPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addCommentAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig
                    .uploadMediaComment
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInCommentAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInCommentFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInCommentBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInCommentPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='addAttachmentInCommentAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.uploadCSV
                )}
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='uploadFileCsvAnonymous'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                        disabled={true}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='uploadFileCsvFree'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='uploadFileCsvBasic'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='uploadFileCsvPro'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='uploadFileCsvAdvanced'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {translate(
                  locale_keys.paymentManagement.functionConfig.limitEmail
                )}
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxEmailsAnonymous'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        disabled={true}
                        className={classNames([''], {
                          ['--error']: errors['maxEmailsAnonymous'],
                        })}
                        {...register('maxEmailsAnonymous', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxEmailsAnonymous'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxEmailsFree'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxEmailsFree'],
                        })}
                        {...register('maxEmailsFree', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxEmailsFree'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxEmailsBasic'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxEmailsBasic'],
                        })}
                        {...register('maxEmailsBasic', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxEmailsBasic'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxEmailsPro'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxEmailsPro'],
                        })}
                        {...register('maxEmailsPro', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxEmailsPro'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
              <td>
                <div className='d-flex flex-column align-items-center'>
                  <Controller
                    name='maxEmailsAdvanced'
                    control={control}
                    rules={{
                      validate: {
                        nonNegativeNumber: validateNonNegativeNumber,
                        integerNumber: validateIntegerNumber,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        size='large'
                        value={field.value}
                        placeholder=''
                        className={classNames([''], {
                          ['--error']: errors['maxEmailsAdvanced'],
                        })}
                        {...register('maxEmailsAdvanced', {
                          required: translate(
                            locale_keys.advertisement.detail.required.input
                          ),
                        })}
                        onChange={(e: any) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='maxEmailsAdvanced'
                    render={({ message }) => (
                      <p className='text--error'>{message}</p>
                    )}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default FunctionsManagement;
