import { IPollType } from '../locale';

const pollType: IPollType = {
  RANK: 'ランキング',
  ARTICLE: '記事',
  COMPARE: '比較',
  JUDGE: '判定',
  SURVEY: 'アンケート',
};

export default pollType;
