import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { toast } from 'react-toastify';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { setAuth } from '@/stores/features/auth.slice';
import { RootState } from '@/stores/reducers';

import { toUpperCaseFirstLetter } from '@/helpers/functions/character.helper';

import apiService from './api.services';
import { default as config } from './endpoint.config';

export const apiInterceptor = (store: ToolkitStore<RootState>) => {
  apiService.interceptors.request.use(
    async (config) => {
      const auth = store.getState()?.auth?.auth;
      if (auth && auth?.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  apiService.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (!error.response) {
        return Promise.reject(error);
      }

      if (originalRequest.url === config.refreshToken) {
        localStorage.clear();
        sessionStorage.clear();

        setTimeout(() => {
          window.location.href = '/';
        }, 0);
        return Promise.reject(error);
      }

      if (
        originalRequest.url === config.login ||
        originalRequest.url === config.register
      ) {
        toast.error(toUpperCaseFirstLetter(error?.response?.data?.errors[0]));
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const persistRoot = useLocalStorage().getPersistRoot();

        const auth = JSON.parse(persistRoot.auth).auth;

        const newToken = await apiService.post(config.refreshToken, {
          token: auth.refresh_token,
        });

        store.dispatch(
          setAuth({
            access_token: newToken.data.data.accessToken,
            refresh_token: newToken.data.data.refreshToken,
            user: newToken.data.data.payload,
          })
        );
        return apiService(originalRequest);
      }

      if (
        error &&
        error?.response?.status === 400 &&
        error?.response?.data?.errors
      ) {
        toast.error(toUpperCaseFirstLetter(error?.response?.data?.errors[0]));
        return Promise.reject(error);
      }
      toast.error('Error occur');
      return Promise.reject(error);
    }
  );
};
