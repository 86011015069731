import { StopOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '@/styles/pages/ads.scss';

import { DescriptionItem } from '@/components/DescriptionItem';
import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { E_LANGUAGE } from '@/helpers/constants/common.constant';
import {
  formatDateFromTimeStamp,
  formatPrice,
  snaketoTitleCase,
  toUpperCaseFirstLetter,
} from '@/helpers/functions/character.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { SaleModel } from '@/helpers/types/sale.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';
import i18n from '@/services/i18n/i18n.config';

interface SaleDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
  location: string;
}

const SaleDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
  location,
}: SaleDetailDrawerProps) => {
  const dispatch = useAppDispatch();
  const [sale, setSale] = useState<SaleModel>();
  const [role, setRole] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [plan, setPlan] = useState('');
  const [nationLocation, setNationLocation] = useState('');
  const [lastestStatus, setLastestStatus] = useState('');

  const getDetailSale = async (selectedId: any) => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.get(
        `${endpointConfig.sale}/${selectedId}`,
        { params: { location } }
      );

      setSale(data?.data);
      setRole(data?.data?.role);
      setUserStatus(data?.data?.userStatus?.toUpperCase());
      setOrderStatus(data?.data?.status?.toUpperCase());
      setPlan(data?.data?.metadata?.planName?.toLowerCase());
      setNationLocation(data?.data?.metadata?.location?.toUpperCase());
      setLastestStatus(data?.data?.latest_invoice?.status?.toUpperCase());
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const lng = i18n.language;

  const onCancelPlan = async () => {
    try {
      await confirmModal({
        title: `${translate(
          locale_keys.saleManagement.detail.confirm.contentCancel
        )} ${sale?.metadata?.username}`,
        content: (
          <>
            <div>
              {translate(locale_keys.saleManagement.detail.confirm.text)}{' '}
              <span className='text-block fw-600'>
                {lng === E_LANGUAGE._EN
                  ? translate(locale_keys.user.USER_FREE)
                  : ''}
              </span>
            </div>
          </>
        ),
        // `Are you sure you want to cancel plan of ${sale?.metadata?.username}?`,
        okText: translate(locale_keys.filter.yes),
        cancelText: translate(locale_keys.filter.no),
        // icon: <InfoCircleFilled />,
      });

      if (!sale?.metadata?.userId)
        toast.error(translate(locale_keys.saleManagement.detail.toastr.error));
      else doCancelPlan(sale?.metadata.userId);
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doCancelPlan = async (userId: string) => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.post(`${endpointConfig.cancel}/${userId}`, {
        location,
      });
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(
          translate(locale_keys.saleManagement.detail.toastr.cancelSuccessful)
        );
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };
  useEffect(() => {
    if (selectedId) {
      getDetailSale(selectedId);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.saleManagement.detail.title)}
        width={640}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <>
            <Space>
              {sale?.role === 'USER_PAID' && (
                <Button
                  type='dashed'
                  danger
                  icon={<StopOutlined />}
                  className='d-flex align-items-center'
                  onClick={onCancelPlan}
                >
                  {translate(locale_keys.saleManagement.detail.cancelPlan)}
                </Button>
              )}
            </Space>
          </>
        }
      >
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.saleManagement.detail.userInformation)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.auth.placeholder.username)}
              content={sale?.metadata?.username}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.auth.placeholder.fullname)}
              content={sale?.fullName}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.email)}
              content={sale?.email}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.userStatus)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (sale?.userStatus === 'ACTIVE'
                      ? 'text-active'
                      : 'text-block')
                  }
                >
                  {translateChartLegend(userStatus, locale_keys.status)}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.role)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (sale?.role === 'USER_PAID' ? 'text-active' : 'text-block')
                  }
                >
                  {translateChartLegend(role.toString(), locale_keys.user)}
                </span>
              }
            />
          </Col>
        </Row>
        <Divider />
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.saleManagement.detail.paymentInfo)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.plan)}
              content={translateChartLegend(
                plan,
                locale_keys.dashboard.planPurchase
              )}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.planType)}
              content={
                sale?.metadata?.months === '1'
                  ? translate(locale_keys.dashboard.planPurchase.monthly)
                  : translate(locale_keys.dashboard.planPurchase.yearly)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.amount)}
              content={formatPrice(sale?.plan?.amount, sale?.plan?.currency)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.advertisement.location)}
              // content={sale?.metadata?.location}
              content={translateChartLegend(
                nationLocation,
                locale_keys.location
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.orderStatus)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (sale?.status === 'active' ? 'text-active' : 'text-block')
                  }
                >
                  {translateChartLegend(orderStatus, locale_keys.status)}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.dateOrder)}
              content={formatDateFromTimeStamp(sale?.created)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(
                locale_keys.saleManagement.detail.currentCycleStart
              )}
              content={formatDateFromTimeStamp(sale?.current_period_start)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(
                locale_keys.saleManagement.detail.nextCycleCharge
              )}
              content={
                sale?.status === 'active' ? (
                  formatDateFromTimeStamp(sale?.current_period_end)
                ) : (
                  <span className={'fw-600 text-block'}>
                    {translateChartLegend(orderStatus, locale_keys.status)}
                  </span>
                )
              }
            />
          </Col>
          {sale?.requestCancel === 'true' && (
            <Col span={12}>
              <DescriptionItem
                title={translate(locale_keys.saleManagement.detail.userRequest)}
                content={
                  <span className='fw-600 text-block'>
                    {translate(locale_keys.saleManagement.detail.requestCancel)}
                  </span>
                }
              />
            </Col>
          )}
        </Row>
        <Divider />
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.saleManagement.detail.lastestInvoice)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.invoiceNumber)}
              content={sale?.latest_invoice?.number}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.filter.status)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (sale?.latest_invoice?.status === 'paid'
                      ? 'text-active'
                      : 'text-block')
                  }
                >
                  {translateChartLegend(
                    lastestStatus,
                    locale_keys.invoiceStatus
                  )}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.amountPaid)}
              content={formatPrice(
                sale?.latest_invoice?.amount_paid,
                sale?.latest_invoice?.currency
              )}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.chargeMethod)}
              content={snaketoTitleCase(
                sale?.latest_invoice?.collection_method
              )}
            />
          </Col>
          {sale?.latest_invoice?.payment_intent?.payment_method && (
            <>
              <Col span={12}>
                <DescriptionItem
                  title={translate(
                    locale_keys.saleManagement.detail.paymentMethod
                  )}
                  content={toUpperCaseFirstLetter(
                    sale?.latest_invoice?.payment_intent?.payment_method?.card
                      ?.brand
                  )}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title={translate(
                    locale_keys.saleManagement.detail.lastDigits
                  )}
                  content={
                    sale?.latest_invoice?.payment_intent?.payment_method?.card
                      ?.last4
                  }
                />
              </Col>
            </>
          )}
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.dateOrder)}
              content={formatDateFromTimeStamp(sale?.latest_invoice?.created)}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.saleManagement.detail.invoiceUrl)}
              content={
                <a href={sale?.latest_invoice?.hosted_invoice_url}>
                  {translate(locale_keys.saleManagement.detail.link)}
                </a>
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SaleDetailDrawer;
