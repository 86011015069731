import { IRequired } from '../locale';

const required: IRequired = {
  username: 'ユーザー名が必要です',
  password: 'パスワードが必要',
  defaultPwd: 'デフォルトのパスワードが必要です',
  fullName: 'フルネームが必要です',
  email: 'メールアドレス必須',
};

export default required;
