import { IVoteManagement } from '../locale';
const voteManagement: IVoteManagement = {
  pollList: {
    author: 'Author',
    category: 'Category',
    listUser: 'List of Polls',
    recommend: 'Recommend by Admin',
    status: 'Status',
    title: 'Title',
    type: 'Type',
    viewDetail: 'View detail',
    listEmail: 'List Emails',
    voteType: {
      ALL: 'ALL',
      RECOMMEND_BY_ADMIN: 'Recommended by admin',
      TOP_VOTE: 'Top vote',
      TOP_LIKE: 'Top like',
      TOP_COMMENT: 'Top comment',
      PRIVATE: 'Private',
      CLOSED: 'Closed',
    },
    detail: {
      title: 'Detail Poll',
      recommend: 'Recommend this poll',
      blockPoll: 'Block this poll',
      activePoll: 'Active this poll',
      published: 'Published',
      like: 'Like',
      comment: 'Comment',
      removeRecommend: 'Remove recommend this poll',
      rejectPoll: 'Reject this poll',
      owner: 'Owner - {{count}} followers',
      question: 'Question',
      vote: 'VOTE',
      peopleChoice: 'People choice ',
      confirm: {
        block: 'Do Block Poll?',
        contentBlock: 'Are you sure you want to block this poll?',
        reject: 'Do Reject Poll?',
        contentReject: 'Are you sure you want to reject this poll?',
        active: 'Do Active Poll?',
        contentActive: 'Are you sure you want to active this poll?',
        recommend: 'Recommend Post',
        contentRecommend: 'Do you want to recommend this post?',
        removeRecommend: 'Delete Recommendation',
        removeContentRecommend:
          'Do you want to remove this post from recommendation?',
      },
      toastr: {
        activeSuccessful: 'Active poll successfully',
        recommentSuccessful: 'Recommend poll successfully',
        removeRecommentSuccessful: 'Remove Recommend poll successfully',
        blockSuccessful: 'Block poll successfully',
      },
    },
  },
  pollConfiguration: {
    freeUser: 'Free User',
    paidUser: 'Paid User',
    mediaUploadSize: 'Media upload size',
    mediaUploadSizeDes: 'Set maximum size of media upload in MB',
    pointCreate: 'Point for poll creating',
    pointCreateDes:
      'Set the number of points received when creating a poll for each type of user',
    pointReceive: 'Point for receiving voting',
    pointReceiveDes:
      'Set the number of points when receiving voting for each type of user',
    pointVoteOther: 'Point for voting others poll',
    pointVoteOtherDes:
      'Set the number of points when voting polls of other users',
    save: 'Save',
    maxSize: 'Maximum Size (MB)',
    refresh: 'Refresh',
    confirm: {
      update: 'Update poll settings',
      contentUpdate: 'Are you sure you want to update poll settings?',
    },
    toastr: {
      updateSuccessful: 'Update poll settings successfully',
    },
  },
  banWord: {
    title: 'New ban word',
    createPlaceholder: 'Create new ban-word',
    banWordList: 'Ban-words List',
    createBanWordSuccessful: 'Create new ban word successfully',
    removeBanWordSuccessful: 'Remove ban-word successfully',
  },
  category: {
    addNewSub: 'Add new sub-category',
    categoryList: 'Category List',
    englishName: 'English name',
    englishNameDes: 'Create new english title',
    japanName: 'Japan name',
    japanNameDes: 'Create new japan title',
    title: 'New category',
    englishSub: 'English sub-category',
    japanSub: 'Japan sub-category',
    japanTitleRequired: 'This field is required',
    toastr: {
      createSubSuccessful: 'Create new sub-category successfully',
      createSuccessful: 'Create new category successfully',
      deleteSuccessful: 'Delete category successfully',
      updateSuccessful: 'Update category successfully',
    },
    confirm: {
      create: 'Create new category?',
      contentCreate: 'Are you sure you want to create new category?',
      contentDelete: 'Are you sure you want to delete category?',
      delete: 'Delete category?',
      contentCreateSub: 'Are you sure you want to create new sub-category?',
      createSub: 'Create new sub-category?',
      update: 'Update category?',
      contentUpdate: 'Are you sure you want to update this category?',
    },
    englishCategory: 'English category',
    englishSubCategory: 'English sub-category',
    japanCategory: 'Japan category',
    japanSubCategory: 'Japan sub-category',
  },
  imageDefault: {
    title: 'New image',
    listImage: 'List image',
    selectFile: 'Select File',
    uploading: 'Uploading',
    startUpload: 'Start upload',
    toastr: {
      createSuccessful: 'Create new default image successfully',
      deleteSuccessful: 'Delete default image successfully',
      uploadSizeWarning: 'Maximum size allowed is',
      uploadTypeWarning:
        'Invalid file type. Only PNG, JPG, and JPEG files are allowed.',
    },
    confirm: {
      contentDelete: 'Are you sure you want to delete this default image?',
      delete: 'Delete default image?',
    },
  },
};

export default voteManagement;
