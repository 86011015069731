import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import '@/styles/pages/login.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import Logo from '@/components/Logo';

import { useAppDispatch } from '@/stores';
import { setAuth, setLoading } from '@/stores/features/auth.slice';

import iconEyes from '@/assets/images/png/icon-eyes.png';
import iconRight from '@/assets/images/png/icon-right.png';
import { E_PATHS } from '@/helpers/constants/common.constant';
import { translate } from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import Language from '@/layouts/header/language';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

const Login = () => {
  useDocumentTitle(translate(locale_keys.heading.login));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    dispatch(setLoading(true));
    const { account, password } = data;

    apiService
      .post(endpointConfig.login, { account, password })
      .then((res) => {
        dispatch(
          setAuth({
            user: res.data.data.payload,
            access_token: res.data.data.accessToken,
            refresh_token: res.data.data.refreshToken,
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const goToForgotPasswordScreen = () => {
    navigate(E_PATHS._FORGOT_PASSWORD);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className='login-container d-flex'>
      <div className='login-container__background'></div>
      <div className='login d-flex align-items-center flex-column justify-content-center'>
        <Logo />
        {/* description */}
        <div className='login__description'>
          <p>{translate(locale_keys.auth.description.login)}</p>
        </div>

        {/* form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='login__form d-flex justify-content-center flex-column'
        >
          <label htmlFor='account'>
            {translate(locale_keys.user.username)}
          </label>
          <input
            type='account'
            id='account'
            placeholder={translate(locale_keys.auth.placeholder.username)}
            className={classNames(['login__form-input'], {
              ['--error']: errors['account'],
            })}
            maxLength={80}
            {...register('account', {
              required: translate(locale_keys.required.username),
            })}
          />

          <ErrorMessage
            errors={errors}
            name='account'
            render={({ message }) => <p className='text--error'>{message}</p>}
          />

          <label className='mt-12' htmlFor='password'>
            {translate(locale_keys.auth.placeholder.password)}
          </label>
          <div className='login__form-group-input'>
            <input
              type={passwordShown ? 'text' : 'password'}
              id='password'
              placeholder='*****************'
              className='form-control login__form-input'
              maxLength={40}
              {...register('password', {
                required: translate(locale_keys.required.password),
              })}
            />
            <img src={iconEyes} alt='icon-eyes' onClick={togglePassword} />
          </div>

          <ErrorMessage
            errors={errors}
            name='password'
            render={({ message }) => <p className='text--error'>{message}</p>}
          />

          <div
            className='login__form-forgot-password mt-12'
            onClick={goToForgotPasswordScreen}
          >
            {translate(locale_keys.auth.text.forgotPassword)}
          </div>

          <button type='submit' className='login__form-btn app-btn--black-21'>
            {translate(locale_keys.auth.button.login)}
            <span>
              <img src={iconRight} alt='icon-right' />
            </span>
          </button>

          <div className='login__form-language'>
            <Language></Language>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
