import { IUser } from '../locale';

const user: IUser = {
  listUser: 'List User',
  listAdmin: 'List Admin',
  username: 'Username',
  email: 'Email',
  fullName: 'Fullname',
  role: 'Role',
  createAt: 'Create At',
  updateAt: 'Update At',
  action: 'Action',
  emailVerified: 'Email Verified',
  status: 'Status',
  gender: 'Gender',
  description: 'Description',
  viewProfile: 'View profile',
  password: 'Password',
  defaultPassword: 'Defaul password',
  birthday: 'Birthday',
  point: 'Points',
  totalPoll: 'Total Polls',
  totalVote: 'Total Votes',
  totalShare: 'Total Shares',
  totalComment: 'Total Comments',
  totalLike: 'Total Likes',
  lastLoginIp: 'Last logged in IP',
  totalFollower: 'Total Followers',
  totalFollowing: 'Total Followings',
  USER_FREE: 'USER_FREE',
  USER_PAID: 'USER_PAID',
  blockAccount: 'Block this account',
  activeAccount: 'Active this account',
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ALL: 'ALL',
  ANONYMOUS: 'Anonymous',
  modal: {
    createAdminTitle: 'Create New Admin Account',
    detailAdminTitle: 'Detail Admin Account',
    detailUserTitle: 'Detail User Account',
    personalInfor: 'Personal Information',
    activityInfor: 'Activity Information',
  },
  toast: {
    createAdminSuccess: 'Create admin account successfully',
    deleteAdminSuccess: 'Delete admin account successfully',
    blockUserSuccess: 'Block user account successfully',
    activeUserSuccess: 'Active user account successfully',
  },
};

export default user;
