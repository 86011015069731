import { Button, DatePicker, Select, SelectProps, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import TablePagination from '@/components/Pagination';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
  LOCATION_TYPE_WITHOUT_ALL,
  SUBSCRIPTION_STATUS,
} from '@/helpers/constants/common.constant';
import { formatPrice } from '@/helpers/functions/character.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import { SaleModel } from '@/helpers/types/sale.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import SaleManagementDetailDrawer from './detail.drawer';
const { RangePicker } = DatePicker;

const SaleManagement = () => {
  useDocumentTitle(translate(locale_keys.menu.sales));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const optionsTypeDefault: SelectProps['options'] = LOCATION_TYPE_WITHOUT_ALL;
  const [optionsType, setOptionsType] = useState(optionsTypeDefault);
  const subscriptionStatusTypeDefault: SelectProps['options'] =
    SUBSCRIPTION_STATUS;
  const [subscriptionStatusType, setSubscriptionStatusType] = useState(
    subscriptionStatusTypeDefault
  );

  const [saleData, setSaleData] = useState<SaleModel[]>([]);
  const [totalBalanceData, setTotalBalanceData] = useState([
    { total: 0, currency: 'usd' },
  ]);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setselectedId] = useState('');
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);

  const defaultValues = {
    timeRange: null,
    startDate: null,
    endDate: null,
    location: LOCATION_TYPE_WITHOUT_ALL[0].value,
    status: SUBSCRIPTION_STATUS[0].value,
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    location: LOCATION_TYPE_WITHOUT_ALL[0].value,
    status: SUBSCRIPTION_STATUS[0].value,
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);

  const columns: ColumnsType<SaleModel> = [
    {
      title: translate(locale_keys.sale.id),
      dataIndex: 'id',
      key: 'id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.id, b?.id),
      width: 260,
    },
    {
      title: translate(locale_keys.sale.amount),
      dataIndex: ['plan'],
      key: 'amount',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.plan?.amount, b?.plan?.amount),
      render: (value) => formatPrice(value?.amount, value?.currency),
    },
    {
      title: translate(locale_keys.user.username),
      dataIndex: ['metadata', 'username'],
      key: 'username',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) =>
        sorterTable(a?.metadata?.username, b?.metadata?.username),
    },
    {
      title: translate(locale_keys.sale.plan),
      dataIndex: ['metadata', 'planName'],
      key: 'planName',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) =>
        sorterTable(a?.metadata?.planName, b?.metadata?.planName),
      render: (value) =>
        translateChartLegend(
          value?.toLowerCase(),
          locale_keys.dashboard.planPurchase
        ),
    },
    {
      title: translate(locale_keys.sale.type),
      dataIndex: ['metadata', 'months'],
      key: 'months',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a?.metadata?.months, b?.metadata?.months),
      render: (months) =>
        months === '1'
          ? translate(locale_keys.dashboard.planPurchase.monthly)
          : translate(locale_keys.dashboard.planPurchase.yearly),
      width: 100,
    },
    {
      title: translate(locale_keys.sale.created),
      dataIndex: 'created',
      key: 'created',
      render: (date: number) => dayjs.unix(date).format('DD-MM-YYYY, HH:mm'),
    },
    {
      title: translate(locale_keys.sale.status),
      sorter: (a, b) => sorterTable(a.status, b.status),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag
          className={
            'fw-600 ' + (status === 'active' ? 'text-active' : 'text-block')
          }
        >
          {translateChartLegend(
            status === 'active' ? 'PAID' : status?.toUpperCase(),
            locale_keys.status
          )}
        </Tag>
      ),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      width: 150,
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record.id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.voteManagement.pollList.viewDetail)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const getListSaleManagements = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);
    try {
      const { data } = await apiService.get(endpointConfig.sale, {
        params,
      });
      setSaleData(data.data);
      setTotalBalanceData(data.totalBalance);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => {
      if (pageSize === pagination.pageSize) {
        return {
          ...prev,
          limit: pageSize,
          page,
          startingAfter:
            page > prev.page ? saleData?.[saleData?.length - 1]?.id : undefined,
          endingBefore: page < prev.page ? saleData?.[0]?.id : undefined,
        };
      }
      return {
        ...prev,
        limit: pageSize,
        page,
        endingBefore: undefined,
        startingAfter: undefined,
      };
    });
  };

  const onSubmit = async (data: any) => {
    const { timeRange, ...rest } = data;
    setSearchValues((prev) => ({
      ...prev,
      ...rest,
      ...(timeRange?.length === 2 && {
        startDate: new Date(timeRange[0])?.toISOString(),
        endDate: new Date(timeRange[1])?.toISOString(),
      }),
    }));
  };

  const onOpenDetailDrawer = (_id: string) => {
    setResetDrawer(!resetDrawer);
    setselectedId(_id);
    setOpenDetailDrawer(true);
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOnRefresh = () => {
    getListSaleManagements();
  };

  useEffect(() => {
    getListSaleManagements();
    setOptionsType((prevOptions: any) =>
      prevOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.location),
      }))
    );
    setSubscriptionStatusType((prevOptions: any) =>
      prevOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.status),
      }))
    );
  }, [searchValues, location]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20' style={{ width: '35%' }}>
              <label
                htmlFor='timeRange'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.sale.created)}
              </label>
              <Controller
                control={control}
                name='timeRange'
                render={({ field }) => (
                  <RangePicker
                    placeholder={[
                      translate(
                        locale_keys.advertisement.detail.startPlaceholder
                      ),
                      translate(
                        locale_keys.advertisement.detail.endPlaceholder
                      ),
                    ]}
                    size='large'
                    style={{ width: '100%' }}
                    format='YYYY-MM-DD'
                    className={classNames([''], {
                      ['--error']: errors['timeRange'],
                    })}
                    onChange={(dates: any, dateStrings: any) => {
                      if (dateStrings[0] === '') {
                        field.onChange(null);
                      } else {
                        field.onChange(dateStrings);
                      }
                    }}
                    value={
                      field.value
                        ? [dayjs(field.value[0]), dayjs(field.value[1])]
                        : null
                    }
                    disabled={[false, false]}
                  />
                )}
              />
            </div>
            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.advertisement.location)}
              </label>
              <div>
                <Controller
                  name='location'
                  control={control}
                  defaultValue={LOCATION_TYPE_WITHOUT_ALL[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 150 }}
                      options={optionsType}
                    />
                  )}
                />
              </div>
            </div>

            <div className='mr-20'>
              <label htmlFor='type' className='form-label filter__form-label'>
                {translate(locale_keys.filter.status)}
              </label>
              <div>
                <Controller
                  name='status'
                  control={control}
                  defaultValue={SUBSCRIPTION_STATUS[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 150 }}
                      options={subscriptionStatusType}
                    />
                  )}
                />
              </div>
            </div>

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading d-flex justify-content-between'>
          <div className='table__heading-title'>
            {translate(locale_keys.sale.listSale)}
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={saleData}
            pagination={false}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1}>
                    <span className='fw-600'>
                      {translate(locale_keys.saleManagement.totalBalance)}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={7}>
                    {totalBalanceData?.map((balance) => (
                      <div className='fw-600' key={balance?.currency}>
                        {balance?.currency?.toUpperCase()}:{' '}
                        {formatPrice(balance?.total, balance.currency)}
                      </div>
                    ))}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>

      <SaleManagementDetailDrawer
        resetDrawer={resetDrawer}
        selectedId={selectedId}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
        location={searchValues?.location}
      ></SaleManagementDetailDrawer>
    </>
  );
};

export default SaleManagement;
