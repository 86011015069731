import { ISiteInformation } from '../locale';

const siteInformation: ISiteInformation = {
  about: 'Pitchcastsについて',
  privacyPolicy: 'プライバシーポリシー',
  termOfService: '利用規約',
  descriptionMaxlength: '内容は20000文字を超えてはいけません',
  english: '英語',
  updateAboutSuccessful: 'Pitchcastsについての内容が更新されました。',
  updatePrivacySuccessful: 'プライバシーポリシーの内容が更新されました。',
  updateTermSuccessful: '利用規約の内容が更新されました',
};
export default siteInformation;
