import { IValidation } from '../locale';

const validation: IValidation = {
  fieldRequired: 'This field is required',
  nonNegativeNumber: 'Please enter a non-negative number',
  valueAsInteger: 'Value must be an integer',
  invalidUrl: 'This is an invalid URL',
};

export default validation;
