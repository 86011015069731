import React, { ReactNode } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useCheckAuth } from '@/hooks/useCheckAuth';

import Loading from '@/components/Loading';
import LoadingOverlay from '@/components/LoadingOverlay';

import { useAppSelector } from '@/stores';

import { E_PATHS } from '@/helpers/constants/common.constant';

export interface LayoutProps {
  children?: ReactNode;
}

const PublicLayout: React.FC<LayoutProps> = () => {
  const { isAuth } = useCheckAuth();
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (isAuth) {
      const timeoutId = setTimeout(() => {
        navigate(E_PATHS._DASHBOARD);
      }, 0);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isAuth]);

  return (
    <div>
      <LoadingOverlay isLoading={loading} />
      <Loading />
      <Outlet />
    </div>
  );
};

export default PublicLayout;
