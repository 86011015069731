import { Button, Input, Select, SelectProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import TablePagination from '@/components/Pagination';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { ADMIN_ROLE } from '@/helpers/constants/admin.constant';
import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
  E_PATHS,
} from '@/helpers/constants/common.constant';
import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import { AdminModel } from '@/helpers/types/admin.types';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import CreateDrawer from './create.drawer';
import DetailDrawer from './detail.drawer';

const AdminAccount = () => {
  useDocumentTitle(translate(locale_keys.heading.adminAccount));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const persistRoot = useLocalStorage().getPersistRoot();
  const auth = persistRoot ? JSON.parse(persistRoot?.auth)?.auth : null;

  const optionsRoleDefault: SelectProps['options'] = ADMIN_ROLE;
  const [optionsRole, setOptionsRole] = useState(optionsRoleDefault);
  const [adminData, setAdminData] = useState([]);
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setselectedId] = useState('');

  const defaultValues = {
    keyword: '',
    role: '',
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    status: '',
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);

  const columns: ColumnsType<AdminModel> = [
    {
      title: translate(locale_keys.user.username),
      dataIndex: 'username',
      key: 'username',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.username, b.username),
    },
    {
      title: translate(locale_keys.user.email),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.email, b.email),
    },
    {
      title: translate(locale_keys.user.fullName),
      dataIndex: 'fullName',
      key: 'fullName',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.fullName, b.fullName),
    },
    {
      title: translate(locale_keys.user.role),
      dataIndex: 'role',
      key: 'role',
      render: (role) => translateChartLegend(role, locale_keys.user),
    },
    {
      title: translate(locale_keys.user.createAt),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => getDateFromDateString(date, 'dd-mm-yyyy', true),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record._id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.user.viewProfile)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const getListAdmin = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);

    try {
      const { data } = await apiService.get(endpointConfig.getAllAdmins, {
        params,
      });

      setAdminData(data?.data);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => ({
      ...prev,
      limit: pageSize,
      page,
    }));
  };

  const handleOnCloseCreateDrawer = () => {
    setOpenCreateDrawer(false);
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const onSubmit = async (data: any) => {
    setSearchValues((prev) => ({
      ...prev,
      ...data,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const onOpenCreateDrawer = () => {
    setOpenCreateDrawer(true);
  };

  const handleOnRefresh = () => {
    getListAdmin();
  };

  const onOpenDetailDrawer = (_id: string) => {
    setOpenDetailDrawer(true);
    setselectedId(_id);
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: 10,
      page: 1,
    }));
  };

  useEffect(() => {
    if (auth && auth?.user && auth?.user?.role === 'ADMIN') {
      navigate(E_PATHS._DASHBOARD);
    } else {
      getListAdmin();
    }

    setOptionsRole((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.user),
      }))
    );
  }, [searchValues, location]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20' style={{ width: '40%' }}>
              <label
                htmlFor='keyword'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.filter.keyword)}
              </label>
              <Controller
                control={control}
                name='keyword'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(
                      locale_keys.filter.keywordPlaceholder
                    )}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor='status' className='form-label filter__form-label'>
                {translate(locale_keys.filter.role)}
              </label>
              <div>
                <Controller
                  name='role'
                  control={control}
                  defaultValue={ADMIN_ROLE[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={optionsRole}
                    />
                  )}
                />
              </div>
            </div>

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading d-flex justify-content-between'>
          <div className='table__heading-title'>
            {translate(locale_keys.user.listAdmin)}
          </div>
          <div
            className='action-btn action-btn--white'
            onClick={onOpenCreateDrawer}
          >
            <span>
              <i className='bi bi-plus-circle'></i>
            </span>
            <span className='ml-10'>
              {translate(locale_keys.filter.create)}
            </span>
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='_id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={adminData}
            pagination={false}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>

      <CreateDrawer
        open={openCreateDrawer}
        onCloseCreateDrawer={handleOnCloseCreateDrawer}
        onRefresh={handleOnRefresh}
      ></CreateDrawer>

      <DetailDrawer
        selectedId={selectedId}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
      ></DetailDrawer>
    </>
  );
};

export default AdminAccount;
