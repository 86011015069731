import { IAuth } from '../locale';

const auth: IAuth = {
  button: {
    login: 'Pitchcasts CMS にログイン', // Login
    register: '登録', // Register
    forgotPassword: '送信', // sent request
    confirmOtp: '確認', // Confirm OTP
    resetPassword: '変更', // Change
  },
  text: {
    forgotPassword: 'パスワードをお忘れですか', // Forgot your password
    register: '登録', // Register
    login: 'ログイン', // Login
    logout: 'ログアウト',
    resendOtp: '再送信', // Resend OTP
    haveAccount: 'アカウントをお持ちですか?', // Already a member?
    notHaveAccount: 'アカウントをお持ちでないですか?', // Not on Pitchcasts yet?
    countDownOTP: 'OTPを入力してください', // Enter your OTP
    notSeeOTP: 'メールに OTP が表示されませんでしたか?',
  },
  title: {
    login: '',
    register: '',
    forgotPassword: '',
  },
  description: {
    login: '無限に広がる意見、思想と本音を展開しよう。', // Let's develop infinite opinions, thoughts and true intentions.
    register: '簡単で簡単です。', // It's quick and easy.
    forgotPassword: 'メールアドレスを入力してください?', // What your email address?
    confirmOtp: '',
    resetPassword: '',
  },
  welcomTitle: {
    login: 'ピッチキャストへようこそ',
    register: 'アカウント作成',
    forgotPassword: 'あなたの Pitchcasts 管理者アカウントを見つけてみましょう',
    confirmOtp: 'メールをご確認ください',
    resetPassword: 'パスワードを変更する',
  },
  placeholder: {
    email: 'ユーザー名または電子メール',
    password: 'パスワード',
    fullname: 'フルネーム',
    confirmPassword: 'パスワードを確認する',
    otp: '0',
    username: 'ユーザー名',
  },
  toast: {
    loginFailed: 'ログインに失敗しました',
    resendOTPSuccessful: 'OTP の再送に成功',
    resetPwdSuccessful: 'パスワードをリセットしました',
  },
};

export default auth;
