import {
  CheckCircleTwoTone,
  CheckOutlined,
  CloseCircleTwoTone,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DescriptionItem } from '@/components/DescriptionItem';
import { confirmModal } from '@/components/ShowConfirm';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import { getDateFromDateString } from '@/helpers/functions/datetime.helper';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

interface UserDetailDrawerProps {
  resetDrawer: boolean;
  selectedId: string;
  open: boolean;
  onCloseDetailDrawer: () => void;
  onRefresh: () => void;
}

const UserDetailDrawer = ({
  resetDrawer,
  selectedId,
  open,
  onCloseDetailDrawer,
  onRefresh,
}: UserDetailDrawerProps) => {
  const dispatch = useAppDispatch();

  const [profile, setProfile] = useState<any>();

  const getDetailUser = async (selectedId: string) => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(
        `${endpointConfig.getAllUsers}/${selectedId}`
      );

      const translateData = data?.data;
      translateData.role = translateChartLegend(
        data?.data?.role,
        locale_keys.user
      );
      translateData.status = translateChartLegend(
        data?.data?.status,
        locale_keys.status
      );

      setProfile(translateData);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onBlockUser = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.confirm.blockUserTitle),
        content: translate(locale_keys.confirm.blockUserDesc),
        okText: translate(locale_keys.filter.block),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doBlock();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doBlock = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.get(
        `${endpointConfig.blockUser}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(translate(locale_keys.user.toast.blockUserSuccess));
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onActiveUser = async () => {
    try {
      await confirmModal({
        title: translate(locale_keys.confirm.activeUserTitle),
        content: translate(locale_keys.confirm.activeUserDesc),
        okText: translate(locale_keys.filter.active),
        cancelText: translate(locale_keys.filter.cancel),
        // icon: <InfoCircleFilled />,
      });

      doActive();
    } catch (e) {
      // User clicked Cancel or closed the modal
    }
  };

  const doActive = async () => {
    dispatch(setLoading(true));

    try {
      const res = await apiService.get(
        `${endpointConfig.activeUser}/${selectedId}`
      );
      if (res?.data?.success) {
        onCloseDetailDrawer();
        toast.success(translate(locale_keys.user.toast.activeUserSuccess));
        onRefresh();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    if (selectedId !== '') {
      getDetailUser(selectedId);
    }
  }, [resetDrawer]);

  return (
    <>
      <Drawer
        title={translate(locale_keys.user.modal.detailUserTitle)}
        width={640}
        placement='right'
        onClose={onCloseDetailDrawer}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          profile?.status === translate(locale_keys.status.ACTIVE) ? (
            <Space>
              <Button
                type='dashed'
                danger
                icon={<StopOutlined />}
                className='d-flex align-items-center'
                onClick={onBlockUser}
              >
                {translate(locale_keys.user.blockAccount)}
              </Button>
            </Space>
          ) : profile?.status === translate(locale_keys.status.BLOCK) ||
            profile?.status === translate(locale_keys.status.INACTIVE) ? (
            <Space>
              <Button
                type='dashed'
                icon={<CheckOutlined />}
                className='d-flex align-items-center text-active text-active--border'
                onClick={onActiveUser}
              >
                {translate(locale_keys.user.activeAccount)}
              </Button>
            </Space>
          ) : null
        }
      >
        <p className='site-description-item-profile-p'>
          {translate(locale_keys.user.modal.personalInfor)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.username)}
              content={profile?.username}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.fullName)}
              content={profile?.fullName}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.email)}
              content={profile?.email}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.emailVerified)}
              content={
                profile?.emailVerified ? (
                  <div className='d-flex align-items-start p-t-5'>
                    <CheckCircleTwoTone twoToneColor='#52c41a' />
                  </div>
                ) : (
                  <div className='d-flex align-items-start p-t-5'>
                    <CloseCircleTwoTone twoToneColor='#EF9B0F' />
                  </div>
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.role)}
              content={profile?.role}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.status)}
              content={
                <span
                  className={
                    'fw-600 ' +
                    (profile?.status === translate(locale_keys.status.ACTIVE)
                      ? 'text-active'
                      : profile?.status ===
                        translate(locale_keys.status.INACTIVE)
                      ? 'text-warning'
                      : profile?.status === translate(locale_keys.status.BLOCK)
                      ? 'text-block'
                      : '')
                  }
                >
                  {profile?.status}
                </span>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.gender)}
              content={
                translateChartLegend(profile?.gender, locale_keys.user) || '-'
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.birthday)}
              content={
                profile?.birthday
                  ? getDateFromDateString(
                      profile?.birthday,
                      'dd-mm-yyyy',
                      false
                    )
                  : '-'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.description)}
              content={profile?.description || '-'}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title={translate(locale_keys.user.lastLoginIp)}
              content={profile?.lastLoginIp || '-'}
            />
          </Col>
        </Row>
        <Divider />

        <p className='site-description-item-profile-p'>
          {translate(locale_keys.user.modal.activityInfor)}
        </p>

        <Row>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.point)}
              content={profile?.configurations?.points}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalPoll)}
              content={profile?.configurations?.totalPolls}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalVote)}
              content={profile?.configurations?.totalVotes}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalLike)}
              content={profile?.configurations?.totalLikes}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalComment)}
              content={profile?.configurations?.totalComments}
            />
          </Col>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalFollower)}
              content={profile?.totalFollowers}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem
              title={translate(locale_keys.user.totalFollowing)}
              content={profile?.totalFollowing}
            />
          </Col>
        </Row>
        <Divider />
      </Drawer>
    </>
  );
};

export default UserDetailDrawer;
