import React from 'react';
import ReactQuill from 'react-quill';

import { E_TYPE_MEDIA } from '@/helpers/constants/voting.constant';

class TextEditor extends React.Component<
  { value: any; onChange?: any; isReadOnly?: boolean },
  { openModal: boolean; typeModal: E_TYPE_MEDIA }
> {
  private quillObj: any;

  constructor(props: any) {
    super(props);

    this.quillObj = null;
  }

  async imageHandler() {
    this.setState({ openModal: true });
  }

  setQuillRef = (ref: any) => {
    this.quillObj = ref;
  };

  render() {
    const { value, onChange, isReadOnly }: any = this.props;

    return (
      <React.Fragment>
        <ReactQuill
          ref={(el: any) => {
            this.setQuillRef(el);
          }}
          value={value}
          placeholder='Write content here...'
          onChange={(e: any) => {
            if (onChange) {
              onChange(e);
            }
          }}
          id='editor'
          modules={{
            toolbar: {
              container: [
                { header: [1, 2, 3, 4, 5, false] },
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { list: 'ordered' },
                { list: 'bullet' },
                { script: 'sub' },
                { script: 'super' },
                { indent: '-1' },
                { indent: '+1' },
                { direction: 'rtl' },
                { align: [] },
                { color: [] },
                { background: [] },
                'link',
                'image',
                'video',
                'clean',
              ],
            },
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'script',
            'indent',
            'direction',
            'align',
            'color',
            'background',
            'link',
            'image',
            'video',
          ]}
          readOnly={isReadOnly}
        />
      </React.Fragment>
    );
  }
}

export default TextEditor;
