import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Input, Select, SelectProps, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import TablePagination from '@/components/Pagination';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  DEFAULT_LIMIT,
  DEFAULT_SIZE,
} from '@/helpers/constants/common.constant';
import { USER_ROLE, USER_STATUS } from '@/helpers/constants/user.constant';
import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { sorterTable } from '@/helpers/functions/table.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import {
  PaginationDefault,
  PaginationInterface,
} from '@/helpers/types/pagination.types';
import { UserModel } from '@/helpers/types/user.types';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import UserDetailDrawer from './detail.drawer';

const UserAccount = () => {
  useDocumentTitle(translate(locale_keys.heading.userAccount));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [userData, setUserData] = useState([]);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setselectedId] = useState('');
  const [resetDrawer, setResetDrawer] = useState<boolean>(false);

  const optionsStatusDefault: SelectProps['options'] = USER_STATUS;
  const optionsRoleDefault: SelectProps['options'] = USER_ROLE;
  const [optionsStatus, setOptionsStatus] = useState(optionsStatusDefault);
  const [optionsRole, setOptionsRole] = useState(optionsRoleDefault);
  const defaultValues = {
    keyword: '',
    status: '',
    role: '',
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const [searchValues, setSearchValues] = useState({
    keyword: '',
    status: '',
    limit: DEFAULT_LIMIT,
    page: DEFAULT_SIZE,
    role: '',
  });
  const [pagination, setPagination] =
    useState<PaginationInterface>(PaginationDefault);

  const columns: ColumnsType<UserModel> = [
    {
      title: translate(locale_keys.user.username),
      dataIndex: 'username',
      key: 'username',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.username, b.username),
    },
    {
      title: translate(locale_keys.user.email),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.email, b.email),
      width: 350,
    },
    {
      title: translate(locale_keys.user.fullName),
      dataIndex: 'fullName',
      key: 'fullName',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => sorterTable(a.fullName, b.fullName),
    },
    {
      title: translate(locale_keys.user.emailVerified),
      dataIndex: 'emailVerified',
      key: 'emailVerified',
      align: 'center',
      render: (_, record) =>
        record.emailVerified ? (
          <CheckCircleTwoTone twoToneColor='#52c41a' />
        ) : (
          <CloseCircleTwoTone twoToneColor='#EF9B0F' />
        ),
    },
    {
      title: translate(locale_keys.user.role),
      dataIndex: 'role',
      key: 'role',
      width: 120,
      sorter: (a, b) => sorterTable(a.role, b.role),
    },
    {
      title: translate(locale_keys.user.status),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => {
        return (
          <Tag
            className={
              'fw-600 ' +
              (status === translate(locale_keys.status.ACTIVE)
                ? 'text-active'
                : status === translate(locale_keys.status.INACTIVE)
                ? 'text-warning'
                : status === translate(locale_keys.status.BLOCK)
                ? 'text-block'
                : '')
            }
          >
            {status}
          </Tag>
        );
      },
      sorter: (a, b) => sorterTable(a.status, b.status),
    },
    {
      title: translate(locale_keys.user.lastLoginIp),
      dataIndex: 'lastLoginIp',
      key: 'lastLoginIp',
      width: 150,
      sorter: (a, b) => sorterTable(a.lastLoginIp, b.lastLoginIp),
    },
    {
      title: translate(locale_keys.user.action),
      key: 'action',
      width: 130,
      render: (_, record) => (
        <div
          className='table__action--default'
          onClick={() => onOpenDetailDrawer(record._id)}
        >
          <span className='mr-8'>
            {translate(locale_keys.user.viewProfile)}
          </span>
          <span>
            <i className='bi bi-box-arrow-up-right'></i>
          </span>
        </div>
      ),
    },
  ];

  const getListUser = async () => {
    dispatch(setLoading(true));

    const params = _.pickBy(searchValues, _.identity);

    try {
      const { data } = await apiService.get(endpointConfig.getAllUsers, {
        params,
      });

      const translateData = data?.data?.map((data: any) => ({
        ...data,
        status: translateChartLegend(data.status, locale_keys.status),
        role: translateChartLegend(data.role, locale_keys.user),
      }));

      setUserData(translateData);
      setPagination(data.paginate);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    if (pageSize !== pagination.pageSize) {
      setPagination({
        ...PaginationDefault,
        pageSize: pageSize,
      });
      page = 1;
    } else {
      setPagination((prev) => ({
        ...prev,
        currentPage: page,
      }));
    }

    setSearchValues((prev) => ({
      ...prev,
      limit: pageSize,
      page,
    }));
  };

  const onSubmit = async (data: any) => {
    setSearchValues((prev) => ({
      ...prev,
      ...data,
      limit: DEFAULT_LIMIT,
      page: DEFAULT_SIZE,
    }));
  };

  const onOpenDetailDrawer = (_id: string) => {
    setResetDrawer(!resetDrawer);
    setselectedId(_id);
    setOpenDetailDrawer(true);
  };

  const onClearFilter = () => {
    reset(defaultValues);
    setSearchValues((prev) => ({
      ...prev,
      ...defaultValues,
      limit: 10,
      page: 1,
    }));
  };

  const handleOnCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOnRefresh = () => {
    getListUser();
  };

  useEffect(() => {
    getListUser();
    setOptionsStatus((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label: translateChartLegend(option.key, locale_keys.status),
      }))
    );

    setOptionsRole((preOptions: any) =>
      preOptions.map((option: any) => ({
        ...option,
        label:
          option.key === 'ALL'
            ? translateChartLegend(option.key, locale_keys.status)
            : translateChartLegend(option.key, locale_keys.user),
      }))
    );
  }, [searchValues, location]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filter'>
          <div className='filter__title'>
            {translate(locale_keys.filter.filterBy)}
          </div>
          <div className='filter__form d-flex'>
            <div className='mr-20' style={{ width: '40%' }}>
              <label
                htmlFor='keyword'
                className='form-label filter__form-label'
              >
                {translate(locale_keys.filter.keyword)}
              </label>
              <Controller
                control={control}
                name='keyword'
                defaultValue=''
                render={({ field }) => (
                  <Input
                    size='large'
                    value={field.value}
                    placeholder={translate(
                      locale_keys.filter.keywordPlaceholder
                    )}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className='mr-20'>
              <label htmlFor='status' className='form-label filter__form-label'>
                {translate(locale_keys.filter.status)}
              </label>
              <div>
                <Controller
                  name='status'
                  control={control}
                  defaultValue={USER_STATUS[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={optionsStatus}
                    />
                  )}
                />
              </div>
            </div>

            <div className='mr-20'>
              <label htmlFor='role' className='form-label filter__form-label'>
                {translate(locale_keys.filter.role)}
              </label>
              <div>
                <Controller
                  name='role'
                  control={control}
                  defaultValue={USER_ROLE[0].value}
                  render={({ field }) => (
                    <Select
                      size='large'
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: 200 }}
                      options={optionsRole}
                    />
                  )}
                />
              </div>
            </div>

            <div className='ms-auto d-flex align-items-end'>
              <Button className='form-btn' onClick={onClearFilter}>
                {translate(locale_keys.filter.clear)}
              </Button>
              <Button
                className='form-btn form-btn--black ml-20'
                htmlType='submit'
              >
                {translate(locale_keys.filter.apply)}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div className='table mt-24'>
        <div className='table__heading'>
          <div className='table__heading-title'>
            {translate(locale_keys.user.listUser)}
          </div>
        </div>
        <div className='table__grid'>
          <Table
            rowKey='_id'
            scroll={{ y: 400 }}
            // loading={true}
            columns={columns}
            dataSource={userData}
            pagination={false}
          />
          <TablePagination
            currentPage={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onPageChange={handlePaginationChange}
          ></TablePagination>
        </div>
      </div>

      <UserDetailDrawer
        resetDrawer={resetDrawer}
        selectedId={selectedId}
        open={openDetailDrawer}
        onCloseDetailDrawer={handleOnCloseDetailDrawer}
        onRefresh={handleOnRefresh}
      ></UserDetailDrawer>
    </>
  );
};

export default UserAccount;
