import { IValidation } from '../locale';

const validation: IValidation = {
  fieldRequired: 'この項目は必須です。',
  nonNegativeNumber: '正の数を入力してください',
  valueAsInteger: '整数をご入力ください。',
  invalidUrl: 'このURLは有効ではありません。',
};

export default validation;
