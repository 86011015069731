import React from 'react';
import ReactPlayer from 'react-player/lazy';

const VideoPlayer = ({ url, view, ...props }: any) => {
  return (
    <ReactPlayer
      url={url}
      width={'100%'}
      height={'100%'}
      controls={view}
      {...props}
    />
  );
};

export default VideoPlayer;
