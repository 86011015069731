import { IPollType } from '../locale';

const pollType: IPollType = {
  RANK: 'Ranking',
  ARTICLE: 'Article',
  COMPARE: 'Comparison',
  JUDGE: 'Judgement',
  SURVEY: 'Questionary',
};

export default pollType;
