import { IUser } from '../locale';

const user: IUser = {
  listUser: 'ユーザーの一覧',
  listAdmin: 'アドミンの一覧',
  username: 'ユーザー名',
  email: 'メールアドレス',
  fullName: 'フルネーム',
  role: '役割',
  createAt: '作成日時',
  updateAt: '更新日時',
  action: 'アクション',
  emailVerified: 'メール認証済み',
  status: 'スターテス',
  gender: '性別',
  description: '説明',
  viewProfile: 'プロフィールを見る',
  password: 'パスワード',
  defaultPassword: 'パスワード',
  birthday: '誕生日',
  point: 'ポイント',
  totalPoll: '総投稿数',
  totalVote: '総投票数',
  totalShare: 'シェア数',
  totalComment: 'コメント数',
  totalLike: 'いいね数',
  lastLoginIp: '最後にログインしたIP',
  totalFollower: '総フォロワー数',
  totalFollowing: '総フォロー数',
  USER_FREE: '無料ユーザー',
  USER_PAID: '有料ユーザー',
  activeAccount: 'アクティブする',
  blockAccount: 'ブロックする',
  FEMALE: '女性',
  MALE: '男性',
  OTHER: '他の',
  ADMIN: 'アドミン',
  SUPER_ADMIN: 'スーパーアドミン',
  ALL: 'すべて',
  ANONYMOUS: '匿名ユーザー',
  modal: {
    createAdminTitle: 'アドミンの新しいアカウント登録',
    detailAdminTitle: 'アドミンの詳細',
    detailUserTitle: 'ユーザーの詳細',
    personalInfor: '個人情報',
    activityInfor: '活動情報',
  },
  toast: {
    createAdminSuccess: 'アドミンのアカウントが登録されました。',
    deleteAdminSuccess: 'アドミンのアカウントが削除されました。',
    blockUserSuccess: 'このユーザーのアカウントはブロックされました。',
    activeUserSuccess: 'このユーザーのアカウントはブロック削除されました。',
  },
};

export default user;
