import { toHalfWidth } from './character.helper';
import { translate } from './language.helper';
import { locale_keys } from '../locales/locale.key';

export const validateCategory = (value: string) => {
  if (value.trim() === '') {
    return translate(locale_keys.validation.fieldRequired);
  }
  return true; // return true if validation succeeds
};

export const validateRequiredNumber = (value: number) => {
  if (!value) {
    return translate(locale_keys.validation.fieldRequired);
  }
  return true; // return true if validation succeeds
};

export const validateRequired = (value: string | number | undefined | null) => {
  if (!value || value === '') {
    return translate(locale_keys.validation.fieldRequired);
  } else {
    return undefined;
  }
};

export const validateNonNegativeNumber = (
  value: string | number | undefined
) => {
  if (value !== undefined) {
    const halfWidthValue = toHalfWidth(value.toString());
    if (isNaN(Number(halfWidthValue)) || Number(halfWidthValue) < 0) {
      return translate(locale_keys.validation.nonNegativeNumber);
    }
  }
};

export const validateIntegerNumber = (value: string | number | undefined) => {
  if (!Number.isInteger(Number(value))) {
    return translate(locale_keys.validation.valueAsInteger);
  }
  return true;
};

export const validURL = (str: string): string | undefined => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // required protocol (http or https)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  if (pattern.test(str)) {
    return undefined;
  } else {
    return translate(locale_keys.validation.invalidUrl);
  }
};

export const filterMarkdownText = (value: string) => {
  return value.replace(/<[^>]*>?/gm, '');
};
