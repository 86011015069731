import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import IconAdminAccount from '@/assets/images/png/icon-admin-account.png';
import IconAdminAccountBold from '@/assets/images/png/icon-admin-account-bold.png';
import IconAds from '@/assets/images/png/icon-ads.png';
import IconAdsBold from '@/assets/images/png/icon-ads-bold.png';
import IconAnnouncement from '@/assets/images/png/icon-announcement.png';
import IconAnnouncementBold from '@/assets/images/png/icon-announcement-bold.png';
import IconDashboard from '@/assets/images/png/icon-dashboard.png';
import IconDashboardBold from '@/assets/images/png/icon-dashboard-bold.png';
import IconGift from '@/assets/images/png/icon-gift.png';
import IconGiftBold from '@/assets/images/png/icon-gift-bold.png';
import IconInquiry from '@/assets/images/png/icon-inquiry.png';
import IconInquiryBold from '@/assets/images/png/icon-inquiry-bold.png';
import IconPayment from '@/assets/images/png/icon-payment.png';
import IconPaymentBold from '@/assets/images/png/icon-payment-bold.png';
import IconSaleBold from '@/assets/images/png/icon-sales-bold.png';
import IconSale from '@/assets/images/png/icon-sales-mng.png';
import IconSiteBold from '@/assets/images/png/icon-site-bold.png';
import IconSite from '@/assets/images/png/icon-site-mng.png';
import IconUserAccount from '@/assets/images/png/icon-user-account.png';
import IconUserAccountBold from '@/assets/images/png/icon-user-account-bold.png';
import IconVotingBold from '@/assets/images/png/icon-voting-bold.png';
import IconVoting from '@/assets/images/png/icon-voting-mng.png';
import { E_PATHS } from '@/helpers/constants/common.constant';
import { locale_keys } from '@/helpers/locales/locale.key';
import { MenuItemInterface } from '@/helpers/types/menu.types';

import MenuItem from './menu-item';
import MenuMultiItem from './menu-multi-item';

export const MENU_ITEMS: MenuItemInterface[] = [
  {
    path: E_PATHS._DASHBOARD,
    icon: IconDashboard,
    iconBold: IconDashboardBold,
    localeKeys: locale_keys.menu.dashboard,
  },
  {
    path: E_PATHS._ADMIN_ACCOUNT,
    icon: IconAdminAccount,
    iconBold: IconAdminAccountBold,
    localeKeys: locale_keys.menu.adminAccount,
  },
  {
    path: E_PATHS._USER_ACCOUNT,
    icon: IconUserAccount,
    iconBold: IconUserAccountBold,
    localeKeys: locale_keys.menu.userAccount,
  },
  {
    path: E_PATHS._VOTING,
    icon: IconVoting,
    iconBold: IconVotingBold,
    localeKeys: locale_keys.menu.voting,
    hasChildren: true,
    children: [
      {
        localeKeys: locale_keys.menu.pollList,
        path: E_PATHS._VOTINGL_LIST,
      },
      {
        localeKeys: locale_keys.menu.pollConfig,
        path: E_PATHS._VOTING_CONFIG,
      },
      {
        localeKeys: locale_keys.menu.banWords,
        path: E_PATHS._VOTING_BAN_WORD,
      },
      {
        localeKeys: locale_keys.menu.pollCategory,
        path: E_PATHS._VOTING_CATEGORY,
      },
      {
        localeKeys: locale_keys.menu.pollImage,
        path: E_PATHS._VOTING_IMAGE,
      },
    ],
  },
  {
    path: E_PATHS._ADVERTISEMENT,
    icon: IconAds,
    iconBold: IconAdsBold,
    localeKeys: locale_keys.menu.advertisement,
    hasChildren: true,
    children: [
      {
        localeKeys: locale_keys.menu.advertisementList,
        path: E_PATHS._ADVERTISEMENT_LIST,
      },
      {
        localeKeys: locale_keys.menu.advertisementRequest,
        path: E_PATHS._ADVERTISEMENT_REQUEST,
      },
    ],
  },
  {
    path: E_PATHS._ANNOUNCEMENT,
    icon: IconAnnouncement,
    iconBold: IconAnnouncementBold,
    localeKeys: locale_keys.menu.announcements,
  },
  {
    path: E_PATHS._SITE,
    icon: IconSite,
    iconBold: IconSiteBold,
    localeKeys: locale_keys.menu.site,
  },
  {
    path: E_PATHS._PAYMENT,
    icon: IconPayment,
    iconBold: IconPaymentBold,
    localeKeys: locale_keys.menu.payment,
    hasChildren: true,
    children: [
      {
        localeKeys: locale_keys.menu.package,
        path: E_PATHS._PAYMENT_PACKAGE,
      },
      {
        localeKeys: locale_keys.menu.function,
        path: E_PATHS._PAYMENT_FUNCTION,
      },
    ],
  },
  {
    path: E_PATHS._SALES,
    icon: IconSale,
    iconBold: IconSaleBold,
    localeKeys: locale_keys.menu.sales,
  },
  {
    path: E_PATHS._FEEDBACK,
    icon: IconInquiry,
    iconBold: IconInquiryBold,
    localeKeys: locale_keys.menu.feedback,
    hasChildren: true,
    children: [
      {
        localeKeys: locale_keys.menu.inquiry,
        path: E_PATHS._INQUIRY,
      },
      {
        localeKeys: locale_keys.menu.report,
        path: E_PATHS._REPORT,
      },
    ],
  },
  {
    path: E_PATHS._POINT,
    icon: IconGift,
    iconBold: IconGiftBold,
    localeKeys: locale_keys.menu.points,
    hasChildren: true,
    children: [
      {
        localeKeys: locale_keys.menu.pointConfigure,
        path: E_PATHS._POINT_CONFIGURE,
      },
      {
        localeKeys: locale_keys.menu.pointExchange,
        path: E_PATHS._POINT_EXCHANGE,
      },
    ],
  },
];

export const MenuList = ({ currentUser }: { currentUser: any }) => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  const filteredMenuItems = MENU_ITEMS.filter((item) => {
    if (currentUser?.role === 'ADMIN' && item.path === E_PATHS._ADMIN_ACCOUNT) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <div className='menu__list'>
      {filteredMenuItems.map((item: MenuItemInterface) =>
        item?.hasChildren ? (
          <MenuMultiItem
            key={item.path}
            item={item}
            currentPath={path}
          ></MenuMultiItem>
        ) : (
          <MenuItem key={item.path} item={item} currentPath={path}></MenuItem>
        )
      )}
    </div>
  );
};

export default MenuList;
