import { IConfirm } from '../locale';

const confirm: IConfirm = {
  createAdminTitle: 'アカウントの登録',
  deleteAdminTitle: 'アカウントの削除',
  blockUserTitle: 'アカウントのブロック',
  activeUserTitle: 'アカウントのブロック削除',
  createAdminDesc: 'アドミンのアカウントを登録してもよろしいでしょうか?',
  deleteAdminDesc: 'このアカウントを削除してもよろしいでしょうか?',
  blockUserDesc: 'このアカウントをブロックしてもよろしいでしょうか?',
  activeUserDesc: 'このアカウントをブロック削除してもよろしいでしょうか?',
};

export default confirm;
