import { Button, Card, Col, Row } from 'antd';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '@/styles/pages/package.scss';

import useDocumentTitle from '@/hooks/useDocumentTitle';

import { useAppDispatch } from '@/stores';
import { setLoading } from '@/stores/features/auth.slice';

import {
  translate,
  translateChartLegend,
} from '@/helpers/functions/language.helper';
import { locale_keys } from '@/helpers/locales/locale.key';
import apiService from '@/services/api/api.services';
import endpointConfig from '@/services/api/endpoint.config';

import PlansDetail from './detail.plan';

const PackagesManagement = () => {
  useDocumentTitle(translate(locale_keys.menu.package));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { plans: [{}] },
  });
  const { fields } = useFieldArray({
    control,
    name: 'plans',
  });

  const getListPackages = async () => {
    dispatch(setLoading(true));

    try {
      const { data } = await apiService.get(endpointConfig.plans);
      reset({
        plans: data?.data?.map((plan: any) => ({
          id: plan._id,
          optionsEn: plan.optionsEn,
          optionsJp: plan.optionsJp,
          title: plan.title,
        })),
      });
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  useEffect(() => {
    getListPackages();
  }, [location]);

  const onSubmit = async (dataForm: any) => {
    dispatch(setLoading(true));
    try {
      const { data } = await apiService.patch(
        endpointConfig.plans,
        dataForm?.plans
      );

      if (data?.success) {
        toast.success(
          translate(
            locale_keys.paymentManagement.packageConfig.toastr.updateSuccessful
          )
        );
        getListPackages();
      }
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 250);
    }
  };

  const onRefresh = () => {
    getListPackages();
  };

  return (
    <div className='package'>
      <form id='basic-form' onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          {fields?.map((item: any, index) => (
            <Col span={8} key={item.id}>
              <Card
                title={translateChartLegend(
                  `${item?.title?.toLowerCase()}Title`,
                  locale_keys.paymentManagement.packageConfig
                )}
              >
                <PlansDetail
                  control={control}
                  register={register}
                  errors={errors}
                  keyName={`plans.${index}`}
                ></PlansDetail>
              </Card>
            </Col>
          ))}
        </Row>
        <div className='d-flex justify-content-end mt-30'>
          <Button className='form-btn form-btn--black' htmlType='submit'>
            {translate(locale_keys.filter.save)}
          </Button>
          <Button className='form-btn ml-20' onClick={onRefresh}>
            {translate(locale_keys.filter.refresh)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PackagesManagement;
