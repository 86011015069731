export default {
  version: '',
  login: '/auth/sign-in-admin',
  register: '/auth/sign-up',
  logout: '/auth/logout',
  validEmailToken: '/auth/valid-email-token',
  verifyEmailToken: '/auth/verify-email-token',
  refreshToken: '/auth/refresh-token',
  forgotPassword: '/auth/forgot-password',

  //user
  getAllUsers: '/users',

  //admin
  getAllAdmins: '/admin',
  actionAccountAdmin: '/admin',
  blockUser: '/admin/block-user',
  activeUser: '/admin/unblock-user',
  actionRecommend: '/admin/recommend-poll',

  //voting
  polls: '/polls',
  categories: '/categories',
  settings: '/settings',
  informations: '/settings/information',
  defaultImages: '/settings/images-poll-default',
  updateStatusPoll: '/admin/update-status-poll',
  pollPresignedUploadUrl: '/aws/presigned-upload',

  //advertisement
  advertisement: '/commercials',
  advertisementRequest: '/commercials/request',

  //announcement
  announcements: '/announcements',

  //me
  meUpdatePassword: '/me/update-password',

  //inquiry
  inquiries: '/inquiries',

  //plans
  plans: '/plans',

  //report
  report: '/flags',

  //sale
  sale: '/payments/subscriptions',
  refund: '/payments/refund',
  cancel: '/payments/cancel',

  //point
  gift: '/gifts',
  exchange: '/gifts/history-gifts',
  updateHistory: '/admin/update-status-history-gift',

  //statistic
  statisticCharts: '/statistic/charts',
  statisticCounters: '/statistic/counters',
  statisticPolls: '/statistic/poll-type',
};
