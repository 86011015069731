import { IAuth } from '../locale';

const auth: IAuth = {
  button: {
    login: 'Login to Pitchcasts CMS',
    register: 'Sign Up',
    forgotPassword: 'Sent Request',
    confirmOtp: 'Confirm OTP',
    resetPassword: 'Change',
  },
  text: {
    forgotPassword: 'Forgot your password',
    register: 'Sign up',
    login: 'Login',
    logout: 'Logout',
    resendOtp: 'Resend OTP',
    notHaveAccount: 'Not on Pitchcasts yet?',
    haveAccount: 'Already a member?',
    countDownOTP: 'Enter your OTP',
    notSeeOTP: 'Didn’t see any OTP in email?',
  },
  title: {
    login: '',
    register: '',
    forgotPassword: '',
  },
  description: {
    confirmOtp: '',
    forgotPassword: 'What your email address?',
    login: 'Efficiently managing your voting system with ease',
    register: "It's quick and easy.",
    resetPassword: '',
  },
  welcomTitle: {
    login: 'Welcome to Pitchcasts',
    register: 'Create an account',
    forgotPassword: "Let's find your Pitchcasts Admin account",
    confirmOtp: 'Please, Check your email',
    resetPassword: 'Change your password',
  },
  placeholder: {
    email: 'Username or Email',
    password: 'Password',
    fullname: 'Full name',
    confirmPassword: 'Confirm password',
    otp: '0',
    username: 'Username',
  },
  toast: {
    loginFailed: 'Login failed',
    resendOTPSuccessful: 'Resend OTP successfully',
    resetPwdSuccessful: 'Reset password successfully',
  },
};

export default auth;
