import { IFilter } from '../locale';

const filter: IFilter = {
  filterBy: '絞り込み',
  keyword: 'キーワード',
  keywordPlaceholder: '氏名、ユーザー名またはメールアドレス',
  role: '役割',
  apply: '検索',
  clear: 'クリア',
  create: '保存',
  status: 'スターテス',
  submit: '登録',
  cancel: 'キャンセル',
  delete: '削除',
  block: 'ブロック',
  active: 'アクティブ',
  yes: 'はい',
  no: 'いいえ',
  update: 'アップデート',
  userPlaceholder: 'ユーザー名またはメールアドレス',
  refresh: 'リフレッシュ',
  save: '保存',
};

export default filter;
