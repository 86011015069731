interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

export const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className='site-description-item-profile-wrapper d-flex'>
    <p className='site-description-item-profile-p-label'>{title}:</p>
    <span
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '180px',
      }}
    >
      {content}
    </span>
  </div>
);
