/* eslint-disable no-unused-vars */

export enum E_LOCAL_STORAGE {
  _TOKEN = 'access_token',
  _REFRESH_TOKEN = 'refresh_token',
  _USER = 'user_info',
  _LANGUAGE = 'i18nextLng',
  _PERSIST_ROOT = 'root',
}

export enum E_LANGUAGE {
  _EN = 'en',
  _JP = 'jp',
}

export enum E_PATHS {
  _DASHBOARD = '/dashboard',
  _USER_ACCOUNT = '/user-account',
  _ADMIN_ACCOUNT = '/admin-account',
  _PAYMENT = '/payment',
  _PAYMENT_PACKAGE = '/payment/package',
  _PAYMENT_FUNCTION = '/payment/function',
  _VOTING = '/voting',
  _VOTINGL_LIST = '/voting/poll-list',
  _VOTING_CONFIG = '/voting/poll-configuration',
  _VOTING_CATEGORY = '/voting/category',
  _VOTING_BAN_WORD = '/voting/word-ban',
  _VOTING_IMAGE = '/voting/image',
  _ADVERTISEMENT = '/advertisement',
  _ADVERTISEMENT_LIST = '/advertisement-list',
  _ADVERTISEMENT_REQUEST = '/advertisement-request',
  _SALES = '/sales',
  _SITE = '/site',
  _ANNOUNCEMENT = '/announcement',
  _INQUIRY = '/feedback/inquiry',
  _POINT = '/point',
  _POINT_CONFIGURE = '/point/configuration',
  _POINT_EXCHANGE = '/point/exchange',
  _REPORT = '/feedback/report',
  _FEEDBACK = '/feedback',

  _LOGIN = '/login',
  _REGISTER = '/sign-up',
  _FORGOT_PASSWORD = '/forgot-password',
  _FORGOT_PASSWORD_CONFIRM_OTP = '/forgot-password/confirm-otp',
  _FORGOT_PASSWORD_RESET = '/forgot-password/reset-password',
}

export const DEFAULT_LIMIT = 10;
export const DEFAULT_SIZE = 1;

export const LOCATION_TYPE = [
  {
    label: 'ALL',
    key: 'ALL',
    value: '',
  },
  {
    label: 'JAPAN',
    key: 'JAPAN',
    value: 'JAPAN',
  },
  {
    label: 'OTHER',
    key: 'OTHER',
    value: 'OTHER',
  },
];

export const LOCATION_TYPE_WITHOUT_ALL = [
  {
    label: 'JAPAN',
    key: 'JAPAN',
    value: 'JAPAN',
  },
  {
    label: 'OTHER',
    key: 'OTHER',
    value: 'OTHER',
  },
];
export type LocationType = 'JAPAN' | 'OTHER';

export const IMAGE_ALLOWED_EXTENSION = ['png', 'jpg', 'jpeg'];
export const VIDEO_ALLOWED_EXTENSION = [
  'ogm',
  'wmv',
  'mpg',
  'webm',
  'ogv',
  'mov',
  'asx',
  'mpeg',
  'mp4',
  'm4v',
  'avi',
];

export const MAX_TITLE_LENGTH = 60;

export const SUBSCRIPTION_STATUS = [
  {
    label: 'ALL',
    key: 'ALL',
    value: 'ALL',
  },
  {
    label: 'COMPLETED',
    key: 'PAID',
    value: 'ACTIVE',
  },
  {
    label: 'INCOMPLETE',
    key: 'INCOMPLETE',
    value: 'INCOMPLETE',
  },
  {
    label: 'INCOMPLETE_EXPIRED',
    key: 'INCOMPLETE_EXPIRED',
    value: 'INCOMPLETE_EXPIRED',
  },
  {
    label: 'UNPAID',
    key: 'UNPAID',
    value: 'UNPAID',
  },
  {
    label: 'PAST_DUE',
    key: 'PAST_DUE',
    value: 'PAST_DUE',
  },
  {
    label: 'CANCELED',
    key: 'CANCELED',
    value: 'CANCELED',
  },
];
